import React, { useContext } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { GlobalDispatchContext, GlobalStateContext } from '../contexts/GlobalContextProvider'

const Overlay = () => {
  const OverlayButton = styled((props) => <motion.div {...props} />)`
    ${tw`fixed top-0 right-0 bottom-0 left-0 bg-white z-10 cursor-pointer`}
  `

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext) || { drawerMenuIsOpen: false }
  const { drawerMenuIsOpen, cartIsOpen } = state

  return (
    <AnimatePresence>
      {(drawerMenuIsOpen || cartIsOpen) && (
        <OverlayButton
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.75 }}
          exit={{ opacity: 0 }}
          onClick={() => {
            dispatch({ type: drawerMenuIsOpen ? 'TOGGLE_DRAWER_MENU' : 'TOGGLE_CART' })
          }}
        />
      )}
    </AnimatePresence>
  )
}

export default Overlay
