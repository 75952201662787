import { gql } from '@apollo/client'

// eslint-disable-next-line import/prefer-default-export
const SEND_FORM = gql`
  mutation wpSubmitGfForm($input: SubmitGfFormInput!) {
    submitGfForm(input: $input) {
      clientMutationId
    }
  }
`

export default SEND_FORM
