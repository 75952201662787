import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router' // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const CookieConsentPopup = () => {
  const location = useLocation()
  return (
    <CookieConsent
      disableStyles
      enableDeclineButton={false}
      buttonId="accept-cookie"
      containerClasses="fixed bottom-0 right-0 w-full md:mr-5 md:mb-5 md:max-w-md p-5 pr-16 bg-white font-mono z-50"
      contentStyle={{ fontSize: '10px' }}
      contentClasses="text-colekt-black opacity-75"
      buttonClasses="accept-cookie"
      buttonText="I accept"
      cookieName="gatsby-gdpr-google-tagmanager"
      onAccept={() => {
        initializeAndTrack(location)
      }}
    >
      Colekt uses cookies to give you the best shopping experience. If you continue to use our services, we will assume
      that you agree to the use of such cookies. Find out more about cookies and{' '}
      <Link to="/privacy-policy" className="underline">
        how you can refuse them.
      </Link>
    </CookieConsent>
  )
}

export default CookieConsentPopup
