/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import SiteWideNotice from '../SiteWideNotice'
import { GlobalStateContext } from '../../contexts/GlobalContextProvider'
import CookieConsentPopup from '../CookieConsentPopup'
import NewsletterPopup from '../Footer/NewsletterPopup'
import Overlay from '../Overlay'
import '../../sass/globals.scss'

const Main = styled.main`
  ${tw`w-full relative`}
`

const Layout = ({ children }) => {
  const state = useContext(GlobalStateContext)

  const { siteWideNotice } = state

  return (
    <>
      {siteWideNotice ? (
        <SiteWideNotice notice="We currently do not ship to your region/country." link="faq" linkLabel="Learn more" />
      ) : null}
      <Main>{children}</Main>
      <Overlay />
      <NewsletterPopup />
      <CookieConsentPopup />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
