import React from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { document } from 'browser-monads'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  drawerMenuIsOpen: false,
  cartIsOpen: false,
  cartIsLoading: false,
  cartTotals: [],
  cart: { isEmpty: true },
  shippingMethods: [],
  locale: 'en-US',
  // TODO: this data needs to be dynamic
  currentMarket: [
    {
      countryCode: 'SE',
      currencyCode: 'SEK',
      currencySymbol: 'kr',
      name: 'Sweden',
      prefix: null,
      suffix: ' kr',
      shippingBanner: 'Introductory offer: Free shipping on all orders',
      shippingInformation:
        '<p>Estimated delivery time within Sweden is 2-4 work days. We handle all shipments through DHL and offer 14 days return policy. For more information, please refer to our <a href="/terms-and-conditions/">Terms and Conditions</a>.</p>',
    },
  ],

  currentMarketCountryCode: null,
  currentMarketCurrencyCode: null,
  markets: [],
  siteWideNotice: false,
  couponCode: '',
}

const targetElementDrawerMenu = document.querySelector('#drawer')
const targetElementCart = document.querySelector('#cart')

function reducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_DRAWER_MENU':
      state.drawerMenuIsOpen ? enableBodyScroll(targetElementDrawerMenu) : disableBodyScroll(targetElementDrawerMenu)
      return {
        ...state,
        drawerMenuIsOpen: state.drawerMenuIsOpen === false,
      }
    case 'TOGGLE_CART':
      state.cartIsOpen ? enableBodyScroll(targetElementCart) : disableBodyScroll(targetElementCart)
      return {
        ...state,
        cartIsOpen: state.cartIsOpen === false,
      }
    case 'TOGGLE_CART_LOADING': {
      return {
        ...state,
        cartIsLoading: action.payload,
      }
    }
    case 'TOGGLE_SITE_WIDE_NOTICE': {
      return {
        ...state,
        siteWideNotice: action.payload,
      }
    }
    case 'GET_CART': {
      return {
        ...state,
        cart: action.payload,
      }
    }

    case 'SET_CART_TOTALS': {
      return {
        ...state,
        cartTotals: action.payload,
      }
    }
    case 'SET_CART': {
      return {
        ...state,
        cart: action.payload,
      }
    }
    case 'SET_MARKETS': {
      return {
        ...state,
        markets: action.payload,
      }
    }
    case 'SET_CURRENT_MARKET': {
      return {
        ...state,
        currentMarket: action.payload,
      }
    }
    case 'SET_CURRENT_MARKET_COUNTRY_CODE': {
      return {
        ...state,
        currentMarketCountryCode: action.payload,
      }
    }
    case 'SET_CURRENT_MARKET_CURRENCY_CODE': {
      return {
        ...state,
        currentMarketCurrencyCode: action.payload,
      }
    }
    case 'SET_SHIPPING_METHODS': {
      return {
        ...state,
        shippingMethods: action.payload,
      }
    }
    case 'SET_COUPON_CODE': {
      return {
        ...state,
        couponCode: action.payload,
      }
    }
    default:
      throw new Error('Bad action type')
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider

// https://codeburst.io/global-state-with-react-hooks-and-context-api-87019cc4f2cf
