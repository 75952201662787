import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { CookiesProvider } from 'react-cookie'
import { client } from './apollo/client'
import { StoreProvider } from './src/contexts/ShopifyStoreContext'
import GlobalContextProvider from './src/contexts/GlobalContextProvider'

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({ element }) => (
  <CookiesProvider>
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <StoreProvider>
          <GlobalContextProvider>{element}</GlobalContextProvider>
        </StoreProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  </CookiesProvider>
)
