exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-products-js": () => import("./../../../src/pages/all-products.js" /* webpackChunkName: "component---src-pages-all-products-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-product-shopify-product-handle-js": () => import("./../../../src/pages/product/{ShopifyProduct.handle}.js" /* webpackChunkName: "component---src-pages-product-shopify-product-handle-js" */),
  "component---src-pages-shopify-collection-handle-js": () => import("./../../../src/pages/{ShopifyCollection.handle}.js" /* webpackChunkName: "component---src-pages-shopify-collection-handle-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

