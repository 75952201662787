import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Formik, Field, Form } from 'formik'
import uuid from 'react-uuid'
import { useCookies } from 'react-cookie'
import isEmpty from 'lodash.isempty'
import Icon from '../../assets/enter.svg'
import CloseIcon from '../../assets/times.svg'
import useFbq from '../FacebookTracking'
import SEND_FORM from '../../../mutations/sendForm'

const Newsletter = styled.div`
  ${tw`fixed bottom-0 right-0 md:left-0 w-full md:ml-5 md:mb-5 md:max-w-md p-5 pr-12 bg-white font-mono z-50`}

  input::placeholder {
    color: black;
  }

  svg {
    fill: #000;
  }
`

const Body = styled.p`
  ${tw`mb-5 font-mono text-base`}
`

const NewsletterPopupWrap = styled.div`
  ${tw`font-extended mb-10 md:mb-0`}
`

const LoaderWrap = styled.div`
  ${tw`inline-block mx-auto pt-5`}
`

const Button = styled.button`
  ${tw`py-4 px-2`}
`

const CloseButton = styled.button`
  ${tw`p-5 absolute top-0 right-0`}
`

const Legal = styled.div`
  ${tw`opacity-50 mt-5 text-sm`}
  a {
    ${tw`underline`}
  }
`

export default function NewsletterPopup() {
  const data = useStaticQuery(graphql`
    query {
      wp {
        globalSettings {
          footer {
            newsletter {
              placeholder
              title
              consent
              termsAndConditions
            }
          }
          localizedStrings {
            thanksForSigningUp
          }
        }
      }
    }
  `)

  const { title, placeholder, consent, termsAndConditions } = data.wp.globalSettings.footer.newsletter

  const [formIsSubmitted, setFormIsSubmitted] = useState(false)

  const successMessage = data.wp.globalSettings.localizedStrings.thanksForSigningUp || 'Thanks for signing up!'

  // eslint-disable-next-line no-unused-vars
  const [sendForm] = useMutation(SEND_FORM)
  const formId = 1

  const fbq = useFbq()

  const isBrowser = typeof window !== `undefined`

  const [isVisible, setIsVisible] = useState(false)

  const localStorageKey = 'newsletter_popup'

  const [cookies] = useCookies(['gatsby-gdpr-google-tagmanager'])

  useEffect(() => {
    if (
      (!isEmpty(cookies) && !localStorage.getItem(localStorageKey)) ||
      localStorage.getItem(localStorageKey) === false
    ) {
      setTimeout(() => {
        setIsVisible(true)
      }, 2500)
    }
  }, [cookies])

  const handleClose = () => {
    setIsVisible(false)
    if (isBrowser) {
      localStorage.setItem(localStorageKey, true)
    }
  }

  if (isVisible) {
    return (
      <Newsletter>
        <CloseButton
          onClick={() => {
            handleClose()
          }}
        >
          <CloseIcon />
        </CloseButton>
        {!formIsSubmitted ? (
          <Formik
            initialValues={{ email: '' }}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                sendForm({
                  variables: {
                    input: {
                      id: formId,
                      clientMutationId: uuid(),
                      entryMeta: { createdById: 1 },
                      fieldValues: [
                        {
                          id: 1,
                          emailValues: { value: values.email },
                        },
                      ],
                    },
                  },
                })

                fbq('Lead')

                actions.setSubmitting(false)
                setFormIsSubmitted(true)
              }, 2050)
            }}
          >
            {({
              // eslint-disable-next-line no-unused-vars
              values,
              isSubmitting,
              errors,
            }) => (
              <NewsletterPopupWrap>
                {isSubmitting ? (
                  <LoaderWrap>Loading...</LoaderWrap>
                ) : (
                  <>
                    <Body>{title}</Body>
                    <Form className="text flex items-center border-b border-black py-2">
                      <Field
                        className="email-field appearance-none bg-white tracking-wide border-none text-lg md:text-2xl w-full mr-3 py-1 px-0 leading-tight focus:outline-none"
                        name="email"
                        placeholder={placeholder}
                        type="email"
                        required
                      />
                      <Button type="submit" disabled={isSubmitting} aria-label="Subscribe">
                        <Icon />
                      </Button>
                    </Form>
                  </>
                )}
                <Legal>
                  <p>
                    {consent ||
                      "By subscribing I consent to Colekt using my personal data (including my email address) to send me commercial electronic messages, including emails, about Colekt's products and services. Read the "}
                    <Link to="/terms-and-conditions/">{termsAndConditions || 'terms and conditions'}</Link>.
                  </p>
                </Legal>
              </NewsletterPopupWrap>
            )}
          </Formik>
        ) : (
          successMessage
        )}
      </Newsletter>
    )
  }
  return null
}
