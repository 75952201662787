import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

// Styled components
const Notice = styled.div`
  ${tw`w-full bg-white py-5 fixed bottom-0 left-0 z-50 shadow
  text-center text-xs `}

  a {
    ${tw`underline`}
  }
`

const SiteWideNotice = props => {
  const { notice, link, linkLabel } = props

  return (
    <>
      {notice ? (
        <Notice>
          {notice} {` `}
          {link ? <Link to={link}>{linkLabel}</Link> : null}
        </Notice>
      ) : null}
    </>
  )
}

SiteWideNotice.propTypes = {
  notice: PropTypes.string,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
}

export default SiteWideNotice
