/* eslint-disable import/prefer-default-export */
import fetch from 'isomorphic-fetch'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.WP_GRAPHQL_URL,
    fetch,
  }),
})
